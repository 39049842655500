/* eslint-disable perfectionist/sort-imports */
import "@solvari/common-fe/polyfills";
import "@/satellites/utils/sentry";
import "@/vue/utils/solvariConfig";

import { initArgus } from "@solvari/common-fe/argus";
import { initAbTesting } from "@solvari/common-fe/ab-testing";
import { initHeyflowForm } from "@/shared/components/heyflow-form/heyflowForm";
/* eslint-enable perfectionist/sort-imports */

void initArgus();
void initAbTesting();
void initHeyflowForm();
